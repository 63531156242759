import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Input, Label, Button, Modal, FormFeedback } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import "../../assets/scss/custom/style.css";

import moment from 'moment';
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import useDecryptedAuth from "../../Utils/useDecryptedAuth";
import i18next from 'i18next';


const MyWithdrawal = () => {
  //meta title
  document.title = i18next.t('Withdrawal PG document title');
  const { getRequest, postRequest } = useRequests();
  const decryptedAuth = useDecryptedAuth();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async () => {
    if(decryptedAuth.kyc_status !== 'Approved') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: i18next.t('For this feature, KYC has been required.'),
      }));
      resetAlertData();
    }
    else {
      setStandardModal1(true);
    }
  }
  
  const [CustomError1, setCustomError1] = React.useState({
    withdrawal_amount: '',
	});
  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      withdrawal_amount: '',
    },
    validationSchema: Yup.object({
      withdrawal_amount: Yup.string()
        .matches(
          /^[#0-9.]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Withdrawal PG Withdrawal Amount is required')),
    }),
    onSubmit: async (values) => {
      try {
        setCustomError1({
          ...CustomError1,
          withdrawal_amount: ''
        });
        let EuroAvailableBalance = parseFloat(CryptoData.euro_available_balance) - parseFloat(CryptoData.euro_freezed);
        if(TransactionData.length > 0) {
          let filteredData = TransactionData.filter(item => item.status === 'Pending');
          let TotalPendingAmount = 0;
          filteredData.forEach(element => {
            TotalPendingAmount = TotalPendingAmount + parseFloat(element.total_amount);
          });
          EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
        }
        if(PropertyCryptoTransactionData.length > 0) {
          let filteredData = PropertyCryptoTransactionData.filter(item => item.status === 'Pending');
          let TotalPendingAmount = 0;
          filteredData.forEach(element => {
            TotalPendingAmount = TotalPendingAmount + parseFloat(element.used_euro_wallet_amount);
          });
          EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
        }
        
        let WithdrawalAmountFee = (parseFloat(values.withdrawal_amount) * parseFloat(SiteSettings.withdrawal_fee)) / 100;
        let TotalWithdrawalAmount = parseFloat(values.withdrawal_amount) + parseFloat(WithdrawalAmountFee);
        if(WithdrawalAccount.length === 0) {
          setCustomError1({
						...CustomError1,
						withdrawal_amount: i18next.t('Withdrawal PG First of all, add your withdrawal account'),
					});
        }
        else if (parseFloat(values.withdrawal_amount) < parseFloat(SiteSettings.minimum_withdrawal)) {
          setCustomError1({
						...CustomError1,
						withdrawal_amount: i18next.t('Withdrawal PG Please enter minimum withdrawal amount'),
					});
        } else if (TotalWithdrawalAmount > EuroAvailableBalance) {
          setCustomError1({
						...CustomError1,
						withdrawal_amount: i18next.t('Insufficient funds'),
					});
        } else {
          let submit1 = document.getElementById('submit1');
          submit1.disabled = true;
          submit1.textContent = i18next.t('Processing...');

          const jsonpar = JSON.stringify({
            withdrawal_amount: values.withdrawal_amount,
            currency_type: 'Euro',
          });
          const res = await postRequest('add-withdrawal-transaction', jsonpar);
          if (res && res.code === 200) {
            handleLoadGetTransactionData();

            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'success',
              message: res.message,
            }));
          }
          else {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'error',
              message: res.message,
            }));
          }
          resetAlertData();
          
          setStandardModal1(false);

          submit1.disabled = false;
          submit1.textContent = i18next.t('Submit');
        }
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const [TotalAmount, setTotalAmount] = React.useState(0);
  const handleChangeWithdrawalAmount = async (e) => {
    let TempWithdrawalAmount = e.target.value;
    setTotalAmount(0);
    if(parseFloat(TempWithdrawalAmount) > 0) {
      let WithdrawalAmountFee = (TempWithdrawalAmount * SiteSettings.withdrawal_fee) / 100;
      let TotalWithdrawalAmount = parseFloat(TempWithdrawalAmount) + parseFloat(WithdrawalAmountFee);
      setTotalAmount(TotalWithdrawalAmount);
    }
  }

  const [Transaction, setTransaction] = React.useState([]);
  const [StandardModal2, setStandardModal2] = React.useState(false);
  const togStandardModal2 = async (elementData) => {
    setTransaction(elementData);
    setStandardModal2(true);
  }

  const [TransactionData, setTransactionData] = React.useState([]);
  const [TransactionTBody, setTransactionTBody] = React.useState('');
  const handleT1Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td>{element.wt_id}</td>
        <td>{moment(element.created_at).format('YYYY-MM-DD | HH:mm')}</td>
        <td>€{element.amount}</td>
        <td>€{element.fees} &nbsp;({element.fees_per}%) </td>
        <td>€{element.total_amount}</td>
        <td><span className={"font-size-14 badge bg-secondary rounded-pill " + (element.status === 'Pending' ? 'badge-soft-warning' : element.status === 'Approved' ? 'badge-soft-success' : 'badge-soft-danger')}>{element.status}</span></td>
        <td className="text-center"><button type="button" className="btn-sm btn btn-primary" onClick={(e)=>togStandardModal2(element)}><i className="mdi mdi-eye"></i></button></td>
      </tr>
    ));

    setTransactionTBody(tempTBody);
  }
  const handleLoadGetTransactionData = async () => {
    const jsonpar = JSON.stringify({
      currency_type: 'Euro',
    });
    const res = await postRequest('get-withdrawal-transaction-data', jsonpar);
    if (res && res.code === 200) {
      handleT1Body(res.data);
      setTransactionData(res.data);
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="7" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

      setTransactionTBody(tempTBody);
    }
  }
  
  const [PropertyCryptoTransactionData, setPropertyCryptoTransactionData] = React.useState([]);
  const handleLoadGetPropertyCryptoTransactionData = async () => {
    const res = await getRequest('get-property-crypto-transaction-data');
    if (res && res.code === 200) {
      setPropertyCryptoTransactionData(res.data.transaction);
    }
  }

  const [CryptoData, setCryptoData] = React.useState([]);
  const handleLoadGetCryptoData = async () => {
    const res = await getRequest('get-crypto-data');
    if (res && res.code === 200) {
      setCryptoData(res.data);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }
  const [SiteSettings, setSiteSettings] = React.useState([]);
  const handleLoadGetSiteSettings = async () => {
    const res = await getRequest('get-site-settings');
    if (res && res.code === 200) {
      setSiteSettings(res.data);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const [WithdrawalAccount, setWithdrawalAccount] = React.useState([]);
  const handleClickWithdrawalAccount = async () => {
    const jsonpar = JSON.stringify({
      currency_type: 'Euro',
    });
    const res = await postRequest('get-user-bank', jsonpar);
    if (res && res.code === 200) {
      setWithdrawalAccount(res.data);
    }
  }
  React.useEffect(() => {
    handleLoadGetTransactionData();
    handleLoadGetPropertyCryptoTransactionData();
    handleLoadGetCryptoData();
    handleLoadGetSiteSettings();
    handleClickWithdrawalAccount();
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Tokenizedgreen')} breadcrumbItem={i18next.t('PG breadcrumbItem Withdrawal')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet" className="active"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col lg="12">
                    <Row>
                      <Col xs={12}>
                        <Link className="back-link" to="/wallet"><i className="bx bx-left-arrow-alt"></i> {i18next.t('Back')}</Link>
                        <div className="clearfix"></div>
                        <Card className="mt-2">
                          <CardBody>
                            <div className="mb-3 h5 card-title">{i18next.t('Withdrawal Transaction')}</div>
                            <Button onClick={(e) => togStandardModal1() } className="btn btn-primary exchange-right-btn float-end mt-1">{i18next.t('Withdraw Fund')}</Button>
                            <div className="clearfix"></div>
                            <div className="table-rep-plugin mt-3">
                              <div className="table-responsive mb-0">
                                <table className="table mb-0" style={{ minWidth: "1000px" }}>
                                  <thead className="table-light">
                                    <tr>
                                      <th>{i18next.t('ID')}</th>
                                      <th>{i18next.t('Date')}</th>
                                      <th>{i18next.t('Amount')}</th>
                                      <th>{i18next.t('Fees')}</th>
                                      <th>{i18next.t('Total Amount')}</th>
                                      <th>{i18next.t('Status')}</th>
                                      <th className="text-center">{i18next.t('Action')}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {TransactionTBody}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal centered backdrop="static" isOpen={StandardModal1}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">{i18next.t('Withdrawal Fund')}</h5>
          <button type="button" onClick={(e)=>setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col xs="12">
              <div className="mb-3">
                <Label className="form-label">{i18next.t('Withdrawal PG Withdrawal Amount')} :</Label>
                <Input type="text"
                  name="withdrawal_amount"
                  placeholder={i18next.t('Withdrawal PG Enter withdrawal amount')}
                  onChange={(e) => {
                    validation1.handleChange(e);
                    handleChangeWithdrawalAmount(e);
                  }}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.withdrawal_amount || ""}
                  invalid={
                    validation1.touched.withdrawal_amount && validation1.errors.withdrawal_amount ? true : false
                  }
                />
                {validation1.touched.withdrawal_amount && validation1.errors.withdrawal_amount ? (
                  <FormFeedback type="invalid">{validation1.errors.withdrawal_amount}</FormFeedback>
                ) : null}
                {CustomError1.withdrawal_amount !== '' ? (<FormFeedback type="invalid" style={{ display: 'block' }}>{CustomError1.withdrawal_amount}</FormFeedback>
                ): null}
              </div>
            </Col>
            <Col xs="12">
              <div className="mb-3">
                <p className="mb-1 mt-3 depends">{i18next.t('Minimum Withdrawal')} : <b>€{SiteSettings.minimum_withdrawal}</b></p>
                <p className="mb-1 mt-2 depends">{i18next.t('Fee')} : <b>{SiteSettings.withdrawal_fee}%</b></p>
                <p className="mb-4 EquityTitle text-start removeTopMobile">{i18next.t('Total Amount')} : €{TotalAmount} </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" 
            id="submit1"
            onClick={(e) => {
              e.preventDefault();
              validation1.handleSubmit();
              return false;
            }}
          >{i18next.t('Submit')}</button>
          <button type="button" className="btn btn-light" data-dismiss="modal" onClick={(e)=>setStandardModal1(false)}>{i18next.t('Close')}</button>
        </div>
      </Modal>

      {/* Fiat Transaction Modal */}

      <Modal isOpen={StandardModal2} backdrop={'static'} centered className="transation" size="lg">
        <div className="modal-header">
          <h5 className="modal-title mt-0">{i18next.t('Withdrawal PG Withdrawal Transaction Details')}</h5>
          <button type="button" onClick={(e)=>setStandardModal2(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body mb-3">
          <div className="row">
            <div className="col-lg-6">
              <p className="mb-1 border-box-1"><b>{i18next.t('ID')} :</b> <span className="">{Transaction.wt_id}</span></p>
              <p className="mb-1 border-box-1"><b>{i18next.t('Date')} :</b> <span className="">{moment(Transaction.created_at).format('YYYY-MM-DD | HH:mm')}</span></p>
              <p className="mb-1 border-box-1"><b>{i18next.t('Amount')} :</b> <span className="">€{Transaction.amount}	</span></p>
              <p className="mb-1 border-box-1"><b>{i18next.t('Fees')} :</b> <span className="">€{Transaction.fees} &nbsp;({Transaction.fees_per}%)</span></p>
              <p className="mb-1 border-box-1"><b>{i18next.t('Total Amount')} :</b> <span className="">€{Transaction.total_amount}	</span></p>
              <p className="mb-1 border-box-1"><b>{i18next.t('Status')} :</b> <span className=""><span className={"font-size-14 badge bg-secondary rounded-pill " + (Transaction.status === 'Pending' ? 'badge-soft-warning' : Transaction.status === 'Approved' ? 'badge-soft-success' : 'badge-soft-danger')}>{Transaction.status}</span></span></p>
            </div>
            <div className="col-lg-6">
              <p className="mb-1 border-box-1"><b>{i18next.t('Payment Detail')} :</b></p>
              <p className="mb-1">Account Holder Name : {WithdrawalAccount.account_holder_name} <br /> IBAN No. : {WithdrawalAccount.bank_account_number} <br /> Routing No. : {WithdrawalAccount.routing_number} <br /> Swift Code : {WithdrawalAccount.swift_code}</p>
              {Transaction.information !== '' && Transaction.information !== null?
                <>
                <p className="mb-2 border-box-1 mt-4"><b>{i18next.t('Transaction Information')} :</b></p>
                <p className="mb-1"><span>{Transaction.information}</span></p>
                </>
              :''}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(MyWithdrawal);

import PropTypes from "prop-types";
import React from "react";
import { Row, Col, CardBody, Card, Container, Form, Input, Label, FormFeedback } from "reactstrap";

//redux
import { useDispatch } from "react-redux";
import { withRouter, Link, useLocation, useHistory } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo-large.png";
import "../../assets/scss/custom/style.css";

import Snackbars from "../../Utils/Snackbars";
import useRequestsWOAccessToken from '../../Utils/useRequestsWOAccessToken';
import i18next from 'i18next';


const Googleauth = props => {
  //meta title
  document.title = i18next.t('Google_Auth PG document title');
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { getRequestWOAccessToken, postRequestWOAccessToken } = useRequestsWOAccessToken();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      auth_code: '',
    },
    validationSchema: Yup.object({
      auth_code: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Google_Auth PG Authentication Code is required'))
        .min(6, i18next.t('Google_Auth PG Authentication Code should be 6 digits!'))
        .max(6, i18next.t('Google_Auth PG Authentication Code should be 6 digits!')),
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit1');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          u_id: UID,
          email: Email,
          password: Password,
          auth_code: values.auth_code,
        });
        const res = await postRequestWOAccessToken('verify-google-auth-code', jsonpar);
        if (res && res.code === 200) {
          let originalText = res.data;

          i18next.changeLanguage(originalText.preferred_language);
          localStorage.setItem("I18N_LANGUAGE", originalText.preferred_language);

          values.email = Email;
          values.password = Password;
          values.username = originalText.first_name + ' ' + originalText.last_name;
          values.kyc_status = originalText.kyc_status;
          values.accesstoken = originalText.accesstoken;
          values.refreshtoken = originalText.refreshtoken;

          delete values.auth_code;

          dispatch(loginUser(values, props.history, { code: 200, status: 'success', message: i18next.t('Login PG Login successful. Welcome back') }));
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();

        submit1.disabled = false;
        submit1.textContent = i18next.t('Submit');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const [UID, setUID] = React.useState(0);
  const [Email, setEmail] = React.useState('');
  const [Password, setPassword] = React.useState('');
  React.useEffect(() => {
    if (typeof location.state !== 'undefined' && typeof location.state.message !== 'undefined') {
      setUID(location.state.id);
      setEmail(location.state.email);
      setPassword(location.state.password);
    }
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}

      <div className="p-0 container-fluid">
        <Row className="g-0">
          <Col className="LeftImg">
            <div className="auth-full-page-content p-4 bg-white">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <Link to="/" className="auth-logo-light">
                      <div className="mb-1">
                        <span className=""> <img src={logo} alt="" height="60" /> </span>
                      </div>
                    </Link>
                  </div>
                  <div className="my-auto">
                    <div>
                      <h5 className="text-black">{i18next.t('Google_Auth PG Google Authenticator')}</h5>
                    </div>
                    <div className="mt-4">
                      <Form className="form-horizontal">
                        <div className="mb-3">
                          <Label className="form-label">{i18next.t('Google_Auth PG Authentication Code')} :</Label>
                          <Input name="auth_code" className="form-control" placeholder={i18next.t('Google_Auth PG Enter Authentication Code')}
                            type="text"
                            onChange={validation1.handleChange}
                            onBlur={validation1.handleBlur}
                            value={validation1.values.auth_code || ""}
                            invalid={
                              validation1.touched.auth_code && validation1.errors.auth_code ? true : false
                            }
                          />
                          {validation1.touched.auth_code && validation1.errors.auth_code ? (
                            <FormFeedback type="invalid">{validation1.errors.auth_code}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mt-3 d-grid">
                          <button className="btn btn-primary btn-block" type="button"
                            id="submit1"
                            onClick={(e) => {
                              e.preventDefault();
                              validation1.handleSubmit();
                              return false;
                            }}
                          >{i18next.t('Submit')}</button>
                        </div>
                      </Form>
                      <div className="mt-3 text-center">
                        <p className="footer-text-color">{i18next.t('Google_Auth PG Go back to')} &nbsp;<Link to="/login" className="fw-medium text-underline">{i18next.t('Login')}</Link>{" "}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="footer-text-color">© {new Date().getFullYear()} Tokenizedgreen.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="rightImg">
            <div className="bgLogin"></div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Googleauth);

Googleauth.propTypes = {
  history: PropTypes.object,
};

import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Modal, Label, Input, Nav, NavItem, TabContent, NavLink, TabPane, Button, FormFeedback } from "reactstrap";
import usdt from "../../assets/images/coin/usdt.png"
import polygon from "../../assets/images/coin/polygon.webp"
import euro from "../../assets/images/coin/euro.png"
import qr from "../../assets/images/QR.png";
import { withRouter, Link } from "react-router-dom";
import classname from "classnames";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import "../../assets/scss/custom/style.css";

import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from 'moment';
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import useDecryptedAuth from "../../Utils/useDecryptedAuth";
import i18next from 'i18next';


const MyWallet = () => {
  //meta title
  document.title = i18next.t('My_Wallet PG document title');
  const { getRequest, postRequest } = useRequests();
  const decryptedAuth = useDecryptedAuth();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };

  
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [StandardModal2, setStandardModal2] = React.useState(false);
  const togStandardModal2 = async () => {
    setStandardModal2(true);
  }

  const [StandardModal3, setStandardModal3] = React.useState(false);
  const togStandardModal3 = async () => {
    setStandardModal3(true);
  }

  const [StandardModal4, setStandardModal4] = React.useState(false);
  const togStandardModal4 = async () => {
    if(decryptedAuth.kyc_status !== 'Approved') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: i18next.t('For this feature, KYC has been required.'),
      }));
      resetAlertData();
    }
    else {
      setStandardModal4(true);
    }
  }

  const validation4 = useFormik({
    enableReinitialize: true,

    initialValues: {
      amount: '',
      wallet_address: '',
      auth_code: '',
    },
    validationSchema: Yup.object({
      amount: Yup.string()
        .matches(
          /^[#0-9.]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('My_Wallet PG Amount is required')),
      wallet_address: Yup.string().required(i18next.t('My_Wallet PG Wallet Address is required')),
      auth_code: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Google_Auth PG Authentication Code is required'))
        .min(6, i18next.t('Google_Auth PG Authentication Code should be 6 digits!'))
        .max(6, i18next.t('Google_Auth PG Authentication Code should be 6 digits!')),
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit4');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          amount: values.amount,
          wallet_address: values.wallet_address,
          auth_code: values.auth_code,
          crypto_type: 'MATIC',
        });
        const res = await postRequest('send-crypto-token', jsonpar);
        if (res && res.code === 200) {
          handleClickCryptoBalanceRefreshWOAlert();

          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'success',
            message: res.message,
          }));
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();
        
        setStandardModal4(false);

        submit1.disabled = false;
        submit1.textContent = i18next.t('Submit');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const [StandardModal5, setStandardModal5] = React.useState(false);
  const togStandardModal5 = async () => {
    if(decryptedAuth.kyc_status !== 'Approved') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: i18next.t('For this feature, KYC has been required.'),
      }));
      resetAlertData();
    }
    else {
      setStandardModal5(true);
    }
  }

  const validation5 = useFormik({
    enableReinitialize: true,

    initialValues: {
      amount: '',
      wallet_address: '',
      auth_code: '',
    },
    validationSchema: Yup.object({
      amount: Yup.string()
        .matches(
          /^[#0-9.]+$/,
          i18next.t('Please enter a valid amount!')
        )
        .required(i18next.t('My_Wallet PG Amount is required')),
      wallet_address: Yup.string().required(i18next.t('My_Wallet PG Wallet Address is required')),
      auth_code: Yup.string()
        .matches(
          /^[0-9]{6}$/,
          i18next.t('Please enter a valid 6-digit auth code!')
        )
        .required(i18next.t('Google_Auth PG Authentication Code is required'))
        .length(6, i18next.t('Google_Auth PG Authentication Code should be 6 digits!')),
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit5');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          amount: values.amount,
          wallet_address: values.wallet_address,
          auth_code: values.auth_code,
          crypto_type: 'USDT',
        });
        const res = await postRequest('send-crypto-token', jsonpar);
        if (res && res.code === 200) {
          handleClickCryptoBalanceRefreshWOAlert();
          
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'success',
            message: res.message,
          }));
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();
        
        setStandardModal5(false);

        submit1.disabled = false;
        submit1.textContent = i18next.t('Submit');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async (elementData) => {
    validation1.setValues({
      ...validation1.values,
      account_holder_name: elementData.account_holder_name,
      bank_account_number: elementData.bank_account_number,
      re_bank_account_number: elementData.bank_account_number,
      bank_name: elementData.bank_name,
      routing_number: elementData.routing_number,
      swift_code: elementData.swift_code,
    });
    setStandardModal1(true);
  }

  const [CustomError1, setCustomError1] = React.useState({
    re_bank_account_number: '',
	});
  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      account_holder_name: '',
      bank_account_number: '',
      re_bank_account_number: '',
      bank_name: '',
      routing_number: '',
      swift_code: '',
    },
    validationSchema: Yup.object({
      account_holder_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid account holder name!')
        )
        .required(i18next.t('My_Wallet PG Account Holder Name is required')),
      bank_account_number: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid bank account number!')
        )
        .required(i18next.t('My_Wallet PG Bank Account Number is required')),
      re_bank_account_number: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid bank account number!')
        )
        .required(i18next.t('My_Wallet PG Re-Enter Bank Account Number is required'))
        .oneOf([Yup.ref('bank_account_number'), null], i18next.t('My_Wallet PG Both bank account number need to be the same!')),
      bank_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid bank name!')
        )
        .required(i18next.t('My_Wallet PG Bank Name is required')),
      routing_number: Yup.string().required(i18next.t('My_Wallet PG Routing Number is required')),
      swift_code: Yup.string().required(i18next.t('My_Wallet PG Swift Code is required')),
    }),
    onSubmit: async (values) => {
      try {

          let submit1 = document.getElementById('submit1');
          submit1.disabled = true;
          submit1.textContent = i18next.t('Processing...');

          const jsonpar = JSON.stringify({
            bank_name: values.bank_name,
            account_holder_name: values.account_holder_name,
            bank_account_number: values.bank_account_number,
            re_bank_account_number: values.re_bank_account_number,
            swift_code: values.swift_code,
            routing_number: values.routing_number,
            currency_type: 'Euro',
          });
          const res = await postRequest('add-update-user-bank', jsonpar);
          if (res && res.code === 200) {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'success',
              message: res.message,
            }));
          }
          else {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'error',
              message: res.message,
            }));
          }
          resetAlertData();
          
          setStandardModal1(false);

          submit1.disabled = false;
          submit1.textContent = i18next.t('Submit');
        
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const handleClickWithdrawalAccount = async () => {
    const jsonpar = JSON.stringify({
      currency_type: 'Euro',
    });
    const res = await postRequest('get-user-bank', jsonpar);
    if (res && res.code === 200) {
      togStandardModal1(res.data);
    }
    else {
      setStandardModal1(true);
    }
  }

  const handleClickCryptoBalanceRefresh = async () => {
    const res = await getRequest('crypto-data-refresh');
    if (res && res.code === 200) {
      setCryptoData(res.data);
      
      if(res.data.crypto_transaction.length > 0) {
        const filteredTransactions1 = res.data.crypto_transaction.filter(transaction => transaction.token_symbol === 'MATIC');
        const filteredTransactions2 = res.data.crypto_transaction.filter(transaction => transaction.token_symbol === 'USDT');
        if(filteredTransactions1.length > 0) {
          handleT1Body(filteredTransactions1);
        }
        else {
          let tempTBody = (<tr>
                          <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                        </tr>);

          setT1BodyData(tempTBody);
        }

        if(filteredTransactions2.length > 0) {
          handleT2Body(filteredTransactions2);
        }
        else {
          let tempTBody = (<tr>
                          <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                        </tr>);

          setT2BodyData(tempTBody);
        }
      }
      else {
        let tempTBody = (<tr>
                        <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

        setT1BodyData(tempTBody);
        setT2BodyData(tempTBody);
      }

      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'success',
        message: res.message,
      }));
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const handleClickCryptoBalanceRefreshWOAlert = async () => {
    const res = await getRequest('crypto-data-refresh');
    if (res && res.code === 200) {
      setCryptoData(res.data);

      if(res.data.crypto_transaction.length > 0) {
        const filteredTransactions1 = res.data.crypto_transaction.filter(transaction => transaction.token_symbol === 'MATIC');
        const filteredTransactions2 = res.data.crypto_transaction.filter(transaction => transaction.token_symbol === 'USDT');
        if(filteredTransactions1.length > 0) {
          handleT1Body(filteredTransactions1);
        }
        else {
          let tempTBody = (<tr>
                          <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                        </tr>);

          setT1BodyData(tempTBody);
        }

        if(filteredTransactions2.length > 0) {
          handleT2Body(filteredTransactions2);
        }
        else {
          let tempTBody = (<tr>
                          <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                        </tr>);

          setT2BodyData(tempTBody);
        }
      }
      else {
        let tempTBody = (<tr>
                        <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

        setT1BodyData(tempTBody);
        setT2BodyData(tempTBody);
      }
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const [T1BodyData, setT1BodyData] = React.useState('');
  const handleT1Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td>{element.hash}</td>
        <td>{element.type}</td>
        <td>{element.value} {element.token_symbol}</td>
        <td>{element.type === 'Send'? element.to_contract_address : element.from_contract_address}</td>
      </tr>
    ));

    setT1BodyData(tempTBody);
  }
  
  const [T2BodyData, setT2BodyData] = React.useState('');
  const handleT2Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td>{element.hash}</td>
        <td>{element.type}</td>
        <td>{element.value} {element.token_symbol}</td>
        <td>{element.type === 'Send'? element.to_contract_address : element.from_contract_address}</td>
      </tr>
    ));

    setT2BodyData(tempTBody);
  }

  const [T3BodyData, setT3BodyData] = React.useState('');
  const handleT3Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td>{element.ft_id}</td>
        <td>{moment(element.created_at).format('YYYY-MM-DD | HH:mm')}</td>
        <td>€{element.amount}</td>
        <td>{element.type}</td>
        <td>{element.description}</td>
        <td>€{element.balance}</td>
      </tr>
    ));

    setT3BodyData(tempTBody);
  }

  const [CryptoData, setCryptoData] = React.useState([]);
  const handleLoadGetCryptoData = async () => {
    const res = await getRequest('get-crypto-data');
    if (res && res.code === 200) {
      setCryptoData(res.data);

      if(res.data.crypto_transaction.length > 0) {
        const filteredTransactions1 = res.data.crypto_transaction.filter(transaction => transaction.token_symbol === 'MATIC');
        const filteredTransactions2 = res.data.crypto_transaction.filter(transaction => transaction.token_symbol === 'USDT');
        if(filteredTransactions1.length > 0) {
          handleT1Body(filteredTransactions1);
        }
        else {
          let tempTBody = (<tr>
                          <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                        </tr>);

          setT1BodyData(tempTBody);
        }

        if(filteredTransactions2.length > 0) {
          handleT2Body(filteredTransactions2);
        }
        else {
          let tempTBody = (<tr>
                          <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                        </tr>);

          setT2BodyData(tempTBody);
        }
      }
      else {
        let tempTBody = (<tr>
                        <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

        setT1BodyData(tempTBody);
        setT2BodyData(tempTBody);
      }
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="4" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

      setT1BodyData(tempTBody);
      setT2BodyData(tempTBody);
    }
  }

  const handleLoadGetFiatData = async () => {
    const jsonpar = JSON.stringify({
      currency_type: 'Euro'
    });
    const res = await postRequest('get-fiat-data', jsonpar);
    if (res && res.code === 200) {
      handleT3Body(res.data);
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="6" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

      setT3BodyData(tempTBody);
    }
  }

  const handleReferralLinkCopied = () => {
    setAlertData({
      variant: 'success',
      message: i18next.t('Wallet Address copied!'),
    });
    resetAlertData();
  };
  
  React.useEffect(() => {
    handleLoadGetCryptoData();
    handleLoadGetFiatData();
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Tokenizedgreen')} breadcrumbItem={i18next.t('PG breadcrumbItem My_Wallet')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet" className="active"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col lg="12">
                    <Nav tabs className="nav-tabs-custom mb-3" role="tablist">
                      <NavItem>
                        <NavLink className={classname({ active: activeTab === "1", })} onClick={() => { toggleTab("1"); }}>Polygon Matic</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink className={classname({ active: activeTab === "2", })} onClick={() => { toggleTab("2"); }}>USDT</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink className={classname({ active: activeTab === "3", })} onClick={() => { toggleTab("3"); }}>Euro</NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="crypto-buy-sell-nav-content" >
                      <TabPane tabId="1" id="01">
                        <Row>
                          <Col lg={6} xs={12}>
                            <Card>
                              <CardBody>
                                <div className="d-flex">
                                  <div className="me-2">
                                    <img src={polygon} className="img-fluid crypto-coin" />
                                  </div>

                                  <div className="flex-grow-1">
                                    <div className="text-muted position-relative">
                                      <h5 className="fullWidth maticRefresh"><span>Polygon Matic</span>
                                        <div className="font-size-24 text-primary send-receive" onClick={(e) => handleClickCryptoBalanceRefresh()}>
                                          <i className="mdi mdi-reload" />
                                        </div>
                                      </h5>
                                      <p className="mb-1 wordBreak">{i18next.t('Wallet Address')} : {CryptoData.wallet_address}</p>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                              <CardBody className="border-top">
                                <Row>
                                  <Col sm="6">
                                    <div>
                                      <p className="text-muted mb-2">{i18next.t('Available Balance')}</p>
                                      <h5>{CryptoData.matic_available_balance} MATIC</h5>
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="text-sm-end mt-4 mt-sm-0">
                                      <p className="text-muted mb-2">{i18next.t('Pending Balance')}</p>
                                      <h5>{CryptoData.matic_pending_balance} MATIC</h5>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={6} xs={12}>
                            <Card>
                              <CardBody className="border-top">
                                <h5 className="mb-3">{i18next.t('My_Wallet PG P')}</h5>
                                <div className="text-center">
                                  <Row>
                                    <Col sm="6">
                                      <div>
                                        <div className="font-size-24 text-primary send-receive mb-2">
                                          <i className="bx bx-send" />
                                        </div>

                                        <p className="text-muted mb-2">{i18next.t('Send')}</p>
                                        <div className="mt-3">
                                          <Link to="#" onClick={(e)=>togStandardModal4()} data-toggle="modal" data-target="#myModal" className="btn btn-primary btn-sm w-md"> {i18next.t('Send')} </Link>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm="6">
                                      <div className="mt-4 mt-sm-0">
                                        <div className="font-size-24 text-primary send-receive mb-2">
                                          <i className="bx bx-import" />
                                        </div>

                                        <p className="text-muted mb-2">{i18next.t('Receive')}</p>
                                        <div className="mt-3">
                                          <Link to="#" className="btn btn-primary btn-sm w-md" onClick={(e)=>togStandardModal2()} data-toggle="modal" data-target="#myModalN"> {i18next.t('Receive')} </Link>
                                        </div>
                                      </div>
                                    </Col>

                                  </Row>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12}>
                            <Card>
                              <CardBody>
                                <h5 className="mb-3">{i18next.t('Recent Transaction')}</h5>
                                <div className="table-rep-plugin">
                                  <div className="table-responsive mb-0" >
                                    <table className="table mb-0" style={{ minWidth: "1000px" }}>
                                      <thead className="table-light">
                                        <tr>
                                          <th>{i18next.t('Transaction Hash')}</th>
                                          <th>{i18next.t('Type')}</th>
                                          <th>{i18next.t('Amount')}</th>
                                          <th>{i18next.t('Recipient')}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {T1BodyData}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2" id="02">
                        <Row>
                          <Col lg={6} xs={12}>
                            <Card>
                              <CardBody>
                                <div className="d-flex">
                                  <div className="me-2">
                                    <img src={usdt} className="img-fluid crypto-coin" />
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="text-muted">
                                      <h5 className="fullWidth maticRefresh"><span>USDT</span>
                                        <div className="font-size-24 text-primary send-receive" onClick={(e) => handleClickCryptoBalanceRefresh()}>
                                          <i className="mdi mdi-reload" />
                                        </div>
                                      </h5>
                                      <p className="mb-1 wordBreak">{i18next.t('Wallet Address')} : {CryptoData.wallet_address}</p>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                              <CardBody className="border-top">
                                <Row>
                                  <Col sm="6">
                                    <div>
                                      <p className="text-muted mb-2">{i18next.t('Available Balance')}</p>
                                      <h5>{CryptoData.usdt_available_balance} USDT</h5>
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="text-sm-end mt-4 mt-sm-0">
                                      <p className="text-muted mb-2">{i18next.t('Pending Balance')}</p>
                                      <h5>{CryptoData.usdt_pending_balance} USDT</h5>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={6} xs={12}>
                            <Card>
                              <CardBody className="border-top">
                                <h5 className="mb-3">{i18next.t('My_Wallet PG P')}</h5>
                                <div className="text-center">
                                  <Row>
                                    <Col sm="6">
                                      <div>
                                        <div className="font-size-24 text-primary send-receive mb-2">
                                          <i className="bx bx-send" />
                                        </div>

                                        <p className="text-muted mb-2">{i18next.t('Send')}</p>
                                        <div className="mt-3">
                                          <Button onClick={(e)=>togStandardModal5()} className="btn btn-primary btn-sm w-md"> {i18next.t('Send')} </Button>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm="6">
                                      <div className="mt-4 mt-sm-0">
                                        <div className="font-size-24 text-primary send-receive mb-2">
                                          <i className="bx bx-import" />
                                        </div>

                                        <p className="text-muted mb-2">{i18next.t('Receive')}</p>
                                        <div className="mt-3">
                                          <Button to="#" className="btn btn-primary btn-sm w-md" onClick={(e)=>togStandardModal3()} > {i18next.t('Receive')} </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12}>
                            <Card>
                              <CardBody>
                                <h5 className="mb-3">{i18next.t('Recent Transaction')}</h5>
                                <div className="table-rep-plugin">
                                  <div className="table-responsive mb-0" >
                                    <table className="table mb-0" style={{ minWidth: "1000px" }}>
                                      <thead className="table-light">
                                        <tr>
                                          <th>{i18next.t('Transaction Hash')}</th>
                                          <th>{i18next.t('Type')}</th>
                                          <th>{i18next.t('Amount')}</th>
                                          <th>{i18next.t('Recipient')}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {T2BodyData}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3" id="03">
                        <Row>
                          <Col xs={12}>
                            <Card>
                              <CardBody>
                                <div className="maticBox">
                                  <div className="d-flex">
                                    <div className="me-2">
                                      <img src={euro} className="img-fluid crypto-coin" />
                                    </div>

                                    <div className="flex-grow-1">
                                      <div className="text-muted">
                                        <h5>Euro</h5>
                                        <p className="mb-1">{i18next.t('Available Balance')} : €{CryptoData.euro_available_balance}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="maticBox1">
                                  <div>
                                    <Link to="/withdrawal" className="btn btn-primary exchange-right-btn me-2">{i18next.t('Withdraw')}</Link>
                                    <button onClick={(e) => handleClickWithdrawalAccount() } className="btn btn-primary exchange-right-btn">{i18next.t('My_Wallet PG Manage Withdrawal Account')}</button>
                                  </div>
                                </div>

                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12}>
                            <Card>
                              <CardBody>
                                <h5 className="mb-3">{i18next.t('Recent Transaction')}</h5>
                                <div className="table-rep-plugin">
                                  <div className="table-responsive mb-0">
                                    <table className="table mb-0" style={{ minWidth: "1000px" }}>
                                      <thead className="table-light">
                                        <tr>
                                          <th>{i18next.t('ID')}</th>
                                          <th>{i18next.t('Date')}</th>
                                          <th>{i18next.t('Amount')}</th>
                                          <th>{i18next.t('Type')}</th>
                                          <th>{i18next.t('Description')}</th>
                                          <th>{i18next.t('Balance')}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {T3BodyData}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>


                  </Col>
                </Row>

                <Modal centered backdrop="static" isOpen={StandardModal4}>
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">{i18next.t('Send')} MATIC</h5>
                    <button type="button" onClick={(e)=>setStandardModal4(false)} className="close" data-dismiss="modal" aria-label="Close" >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">{i18next.t('Amount')} :</Label>
                          <Input type="text" placeholder={i18next.t('My_Wallet PG Enter Amount')}
                            name="amount"
                            onChange={validation4.handleChange}
                            onBlur={validation4.handleBlur}
                            value={validation4.values.amount || ""}
                            invalid={
                              validation4.touched.amount && validation4.errors.amount ? true : false
                            }
                          />
                          {validation4.touched.amount && validation4.errors.amount ? (
                            <FormFeedback type="invalid">{validation4.errors.amount}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">{i18next.t('Please enter receiver')} Polygon MATIC {i18next.t('Wallet Address')} :</Label>
                          <Input type="text" placeholder={i18next.t('Enter')+" Polygon MATIC "+i18next.t('Wallet Address')}
                            name="wallet_address"
                            onChange={validation4.handleChange}
                            onBlur={validation4.handleBlur}
                            value={validation4.values.wallet_address || ""}
                            invalid={
                              validation4.touched.wallet_address && validation4.errors.wallet_address ? true : false
                            }
                          />
                          {validation4.touched.wallet_address && validation4.errors.wallet_address ? (
                            <FormFeedback type="invalid">{validation4.errors.wallet_address}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={12}>
                        <label className="form-label form-label note"><b>{i18next.t('Note')}:</b> {i18next.t('My_Wallet PG label3')}</label>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">{i18next.t('Google_Auth PG Enter Authentication Code')} :</Label>
                          <Input type="text" placeholder={i18next.t('Google_Auth PG Enter Authentication Code')}
                            name="auth_code"
                            onChange={validation4.handleChange}
                            onBlur={validation4.handleBlur}
                            value={validation4.values.auth_code || ""}
                            invalid={
                              validation4.touched.auth_code && validation4.errors.auth_code ? true : false
                            }
                          />
                          {validation4.touched.auth_code && validation4.errors.auth_code ? (
                            <FormFeedback type="invalid">{validation4.errors.auth_code}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary"
                      id="submit4"
                      onClick={(e) => {
                        e.preventDefault();
                        validation4.handleSubmit();
                        return false;
                      }}
                    >{i18next.t('Submit')}</button>
                    <button type="button" className="btn btn-light" data-dismiss="modal" onClick={(e)=>setStandardModal4(false)} >{i18next.t('Close')}</button>
                  </div>
                </Modal>

                <Modal centered backdrop="static" isOpen={StandardModal2}>
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">{i18next.t('Received')} MATIC</h5>
                    <button type="button" onClick={(e)=>setStandardModal2(false)} className="close" data-dismiss="modal" aria-label="Close" >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <img src={CryptoData.wallet_address_qrcode} alt="QR-Code" className="img-thumbnail qr-size" />
                      <Col md={12}>
                        <div className="mb-3 position-relative copy-wallet-address">
                          <Input name="text" type="text" value={CryptoData.wallet_address} className="mb-2" readOnly/>
                          <CopyToClipboard text={CryptoData.wallet_address} onCopy={handleReferralLinkCopied}>
                          <i className="bx bx-copy-alt right-copy"></i>
                          </CopyToClipboard>
                          <Label className="form-label">{i18next.t('Send')} MATIC {i18next.t('My_Wallet PG Label1')}</Label>
                          <Label className="form-label note mb-0 mt-3"><b>{i18next.t('Note')}:</b> {i18next.t('My_Wallet PG Label2')}</Label>
                        </div>
                      </Col>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-light" data-dismiss="modal" onClick={(e)=>setStandardModal2(false)} >{i18next.t('Close')}</button>
                  </div>
                </Modal>

                <Modal centered backdrop="static" isOpen={StandardModal1}>
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">{i18next.t('My_Wallet PG Manage Withdrawal Account')}</h5>
                    <button type="button" onClick={(e)=>setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close" >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <div className="form-group mb-3 col-12">
                        <label className="form-label">{i18next.t('My_Wallet PG Account Holder Name')} : </label>
                        <input type="text" className="form-control"
                          name="account_holder_name"
                          placeholder={i18next.t('My_Wallet PG Enter Account Holder Name')}
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.account_holder_name || ""}
                        />
                        {validation1.touched.account_holder_name && validation1.errors.account_holder_name ? (
                          <FormFeedback type="invalid">{validation1.errors.account_holder_name}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-group mb-3 col-12">
                        <label className="form-label">{i18next.t('My_Wallet PG Bank Account Number')} : </label>
                        <input type="text"className="form-control"
                          name="bank_account_number"
                          placeholder={i18next.t('My_Wallet PG Enter Bank Account Number')}
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.bank_account_number || ""}
                        />
                        {validation1.touched.bank_account_number && validation1.errors.bank_account_number ? (
                          <FormFeedback type="invalid">{validation1.errors.bank_account_number}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-group mb-3 col-12">
                        <label className="form-label">{i18next.t('My_Wallet PG Re-Enter Bank Account Number')} : </label>
                        <input type="text" className="form-control"
                          name="re_bank_account_number"
                          placeholder={i18next.t('My_Wallet PG Re-Enter Bank Account Number')}
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.re_bank_account_number || ""}
                        />
                        {validation1.touched.re_bank_account_number && validation1.errors.re_bank_account_number ? (
                          <FormFeedback type="invalid">{validation1.errors.re_bank_account_number}</FormFeedback>
                        ) : null}
                        {CustomError1.re_bank_account_number !== '' ? (<FormFeedback type="invalid" style={{ display: 'block' }}>{CustomError1.re_bank_account_number}</FormFeedback>
                        ): null}
                      </div>
                      <div className="form-group mb-3 col-12">
                        <label className="form-label">{i18next.t('My_Wallet PG Bank Name')} : </label>
                        <input type="text" className="form-control"
                          name="bank_name"
                          placeholder={i18next.t('My_Wallet PG Enter Bank Name')}
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.bank_name || ""}
                        />
                        {validation1.touched.bank_name && validation1.errors.bank_name ? (
                          <FormFeedback type="invalid">{validation1.errors.bank_name}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-group mb-3 col-12">
                        <label className="form-label">{i18next.t('My_Wallet PG Routing Number')} : </label>
                        <input type="text" className="form-control"
                          name="routing_number"
                          placeholder={i18next.t('My_Wallet PG Enter Routing Number')}
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.routing_number || ""}
                        />
                        {validation1.touched.routing_number && validation1.errors.routing_number ? (
                          <FormFeedback type="invalid">{validation1.errors.routing_number}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-group mb-3 col-12">
                        <label className="form-label">{i18next.t('My_Wallet PG Swift Code')} : </label>
                        <input type="text" className="form-control"
                          name="swift_code"
                          placeholder={i18next.t('My_Wallet PG Enter Swift Code')}
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.swift_code || ""}
                        />
                        {validation1.touched.swift_code && validation1.errors.swift_code ? (
                          <FormFeedback type="invalid">{validation1.errors.swift_code}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary"
                      id="submit1"
                      onClick={(e) => {
                        e.preventDefault();
                        validation1.handleSubmit();
                        return false;
                      }}
                    >{i18next.t('Submit')}</button>
                    <button type="button" className="btn btn-light" data-dismiss="modal" onClick={(e)=>setStandardModal1(false)}>{i18next.t('Close')}</button>
                  </div>
                </Modal>

                <Modal centered backdrop="static" isOpen={StandardModal5} >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">{i18next.t('Send')} USDT</h5>
                    <button type="button" onClick={(e)=>setStandardModal5(false)} className="close" data-dismiss="modal" aria-label="Close" >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">{i18next.t('Amount')} :</Label>
                          <Input type="text" placeholder={i18next.t('My_Wallet PG Enter Amount')}
                            name="amount"
                            onChange={validation5.handleChange}
                            onBlur={validation5.handleBlur}
                            value={validation5.values.amount || ""}
                            invalid={
                              validation5.touched.amount && validation5.errors.amount ? true : false
                            }
                          />
                          {validation5.touched.amount && validation5.errors.amount ? (
                            <FormFeedback type="invalid">{validation5.errors.amount}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">{i18next.t('My_Wallet PG Please enter receiver')} Polygon USDT {i18next.t('Wallet Address')} :</Label>
                          <Input type="text" placeholder={i18next.t('Enter')+" Polygon USDT "+i18next.t('Wallet Address')}
                            name="wallet_address"
                            onChange={validation5.handleChange}
                            onBlur={validation5.handleBlur}
                            value={validation5.values.wallet_address || ""}
                            invalid={
                              validation5.touched.wallet_address && validation5.errors.wallet_address ? true : false
                            }
                          />
                          {validation5.touched.wallet_address && validation5.errors.wallet_address ? (
                            <FormFeedback type="invalid">{validation5.errors.wallet_address}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={12}>
                        <label className="form-label form-label note"><b>{i18next.t('Note')}:</b> {i18next.t('My_Wallet PG label3')}</label>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">{i18next.t('Google_Auth PG Authentication Code')} :</Label>
                          <Input type="text" placeholder={i18next.t('Google_Auth PG Enter Authentication Code')}
                            name="auth_code"
                            onChange={validation5.handleChange}
                            onBlur={validation5.handleBlur}
                            value={validation5.values.auth_code || ""}
                            invalid={
                              validation5.touched.auth_code && validation5.errors.auth_code ? true : false
                            }
                          />
                          {validation5.touched.auth_code && validation5.errors.auth_code ? (
                            <FormFeedback type="invalid">{validation5.errors.auth_code}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>                     

                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" 
                      id="submit5"
                      onClick={(e) => {
                        e.preventDefault();
                        validation5.handleSubmit();
                        return false;
                      }}
                    >{i18next.t('Submit')}</button>
                    <button type="button" className="btn btn-light" data-dismiss="modal" onClick={(e)=>setStandardModal5(false)} >{i18next.t('Close')}</button>
                  </div>
                </Modal>

                <Modal centered backdrop="static" isOpen={StandardModal3}>
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">{i18next.t('Received')} USDT</h5>
                    <button type="button" onClick={(e)=>setStandardModal3(false)} className="close" data-dismiss="modal" aria-label="Close" >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <img src={CryptoData.wallet_address_qrcode} alt="QR-Code" className="img-thumbnail qr-size" />
                      <Col md={12}>
                        <div className="mb-3 position-relative copy-wallet-address">
                          <Input name="text" type="text" value={CryptoData.wallet_address} className="mb-2" readOnly/>
                          <CopyToClipboard text={CryptoData.wallet_address} onCopy={handleReferralLinkCopied}>
                          <i className="bx bx-copy-alt right-copy"></i>
                          </CopyToClipboard>
                          <Label className="form-label">{i18next.t('Send')} USDT {i18next.t('My_Wallet PG Label1')}</Label>
                          <Label className="form-label note mb-0 mt-3"><b>{i18next.t('Note')}:</b> {i18next.t('My_Wallet PG Label2')}</Label>
                        </div>
                      </Col>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-light" data-dismiss="modal" onClick={(e)=>setStandardModal3(false)}>{i18next.t('Close')}</button>
                  </div>
                </Modal>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MyWallet);
